<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-badge
            href="#"
            class="d-block"
            variant="light-success"
          >
            Gateway
          </b-badge>
        </b-col>
        <b-col>
          <b-badge
            href="#"
            class="d-block"
            variant="light-success"
          >
            Backend
          </b-badge>
        </b-col>
        <b-col>
          <b-badge
            href="#"
            class="d-block"
            variant="light-success"
          >
            Cluster
          </b-badge>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <!--  Here comesTheTable-->
      <b-row v-if="waitingActive">
        <b-col>
          <b-progress
            v-model="barProgress"
            max="100"
          />
        </b-col>

      </b-row>
      <b-row class="m-2">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-badge class="mr-1" variant="light-success">
            {{ this.totalActiveViews }}
          </b-badge>

          <b-button
            @click="startActive()"
          >
            start all active
          </b-button>
          <b-button
            size="sm"
            class="ml-1"
            @click="InstantRestartAllActive()"
          >
            Instant-Restart-All
          </b-button>
<!--          <b-button-->
<!--              size="sm"-->
<!--              class="ml-1"-->
<!--              @click="restartAutoStartAll()"-->
<!--          >-->
<!--            RestartAutoStartForAccount-->
<!--          </b-button>-->
        </b-col>
        <b-col
          md="2"
          sm="8"
          class="my-1"
        >
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- none --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            style="min-height: 30vh"
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(duration)="data">
              <b-progress
                :max="data.item.durationTotal"
                :variant="`${resolveProgressBarVariant(data.item)}`"
              >
                <b-progress-bar :value="data.item.durationTotal - data.item.duration">
                  <strong>{{ data.item.durationTotal - data.item.duration }} / {{ data.item.durationTotal }}</strong>
                </b-progress-bar>

              </b-progress>
              <!--          <b-badge-->
              <!--            pill-->
              <!--            :variant="`light-${resolveUserStatusVariant(data.item.duration)}`"-->
              <!--            class="text-capitalize"-->
              <!--          >-->
              <!--            {{ data.item.duration }}-->
              <!--          </b-badge>-->
            </template>
            <template #cell(viewers)="data">
              <b-badge :variant="data.item.viewers.statusOfBadge">
                {{ data.item.viewers.amountString }}
              </b-badge>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(geminosname)="data">

              <b-link
                v-if="isjemkein"
                :href="'https://console.firebase.google.com/u/0/project/geminos-4dedd/firestore/data/~2FUser~2F/' + data.item.uid"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.geminosname }}
              </b-link>
              <div
                v-if="!isjemkein"
              >
                <b-button v-b-modal.modal-UserInfo
                          @click="fetchCompleteUser(data.item)">
                  {{ data.item.geminosname }}
                </b-button>

              </div>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">-->
                <!--              <feather-icon icon="FileTextIcon" />-->
                <!--              <span class="align-middle ml-50">Details</span>-->
                <!--            </b-dropdown-item>-->

                <b-dropdown-item @click="extendPackage(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Extend Package by 1 day</span>
                </b-dropdown-item>
                <b-dropdown-item @click="restartUser(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Restart User</span>
                </b-dropdown-item>
                <b-dropdown-item @click="instantRestartUser(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">InstantRestart User</span>
                </b-dropdown-item>
                <b-dropdown-item @click="activateNameChange(data.item, true)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Activate Namechange</span>
                </b-dropdown-item>
                <b-dropdown-item @click="activateNameChange(data.item, false)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Deactivate Namechange</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-login
                  @click="selectedUser = data.item"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Change Name</span>
                </b-dropdown-item>

                <!--            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">-->
                <!--              <feather-icon icon="EditIcon" />-->
                <!--              <span class="align-middle ml-50">Edit</span>-->
                <!--            </b-dropdown-item>-->
                <b-dropdown-item
                  v-if="isjemkein"
                  @click="stopPackage(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">End Package</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>
            <template #cell(twitchname)="data">
              <b-link
                :href="'https://www.twitch.tv/' + data.item.twitchname"
                referrerpolicy="no-referrer"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.twitchname }}
              </b-link>
            </template>
          </b-table>
        </b-col>

      </b-row>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ items.length }} to {{ items.length }} of {{ items.length }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <!--              totalUserss-->
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <div v-html="prettifyJson(completeSelectedUser, true)"></div>
    </b-card>

    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="Login Form"
    >
      Change Twitchname for {{ selectedUser.twitchname }}
      <b-form>
        <b-form-group>
          <label>Twitchname</label>
          <b-form-input
            v-model="newTwitchname"
            type="text"
            placeholder="Twitchname"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="success"
          @click="saveTwitchname(selectedUser,newTwitchname), ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="modal-UserInfo"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        title="User Info"
        class="modal-xl"
        size="xl"
    >
      Settings for
      <div id="result-after"/>

      <b-form>
        <b-form-group>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
            variant="success"
            @click="ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'

/* eslint-disable */
import {
  BTable, BCard, BAvatar, BBadge, BLink, BRow,  BProgress, BModal, VBModal, BForm,
  BProgressBar, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import admin from "firebase-admin";

export default {
  components: {
    BTable,
    BAvatar,
    BProgress,
    BProgressBar,
    BCard,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BRow,
    BModal,
    BForm,
    BFormInput,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      completeSelectedUser: {"none": "none"},
      barProgress: 0,
      totalActiveViews: 0,
      waitingActive: false,
      selectedUser: { "id": 47, "geminosname": "Notnetic", "twitchname": "ducknetic", "license": "3437-3886-3296", "duration": -56, "durationLeft": -56, "durationTotal": 103, "plan": "Starter", "viewers": { "amountString": "20/15", "statusOfBadge": "light-success" }, "status": 2, "uid": "4Mh9ehv8nrYN8ABkLfinQI7I2yH3" },
      perPage: 25,
      pageOptions: [15, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'status',
      isjemkein: false,
      sortDesc: true,
      newTwitchname: "",
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Id',
        },
        {
          key: 'geminosname', label: 'Geminos-Name',
        },
        { key: 'twitchname', label: 'Twitch-Name', sortable: true },
        { key: 'license', label: 'License Key', sortable: true },
        { key: 'plan', label: 'Plan', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true },
        { key: 'viewers', label: 'Viewers' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Action' },
      ],
      items: [
        {
          id: 1,
          // eslint-disable-next-line global-require
          geminosname: 'DisATest',
          twitchname: "Korrie O'Crevy",
          license: 'Nuclear Power Engineer',
          plan: 'kocrevy0@thetimes.co.uk',
          viewers: 'Krasnosilka',
          duration: '10',
          status: 2,
        },
      ],
      status: [{
        1: 'Inactive', 2: 'Active', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-danger', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    console.log("NOIW")
    this.resolveUserData()
    this.refreshForCurrentViews()
    this.getUpdates()
    this.totalRows = this.items.length
  },
  methods: {
    setUser() {
      document.getElementById("result-after").innerHTML = "<pre>"+this.prettifyJson(this.completeSelectedUser, true) +"</pre>"
    },
    prettifyJson(json, prettify) {
      if (typeof json !== 'string') {
        if (prettify) {
          json = JSON.stringify(json, undefined, 4);
        } else {
          json = JSON.stringify(json);
        }
      }
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
          function(match) {
            let cls = "<span>";
            if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                cls = "<span class='text-danger'>";
              } else {
                cls = "<span>";
              }
            } else if (/true|false/.test(match)) {
              cls = "<span class='text-primary'>";
            } else if (/null/.test(match)) {
              cls = "<span class='text-info'>";
            }
            return cls + match + "</span>";
          }
      );
    },
    fetchCompleteUser(userItem) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(userItem.uid).get().then( result => {
        this.completeSelectedUser = result.data()
        delete this.completeSelectedUser.email
        delete this.completeSelectedUser.ability
        this.setUser()
      })
    },
    saveTwitchname(userItem, newName) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(userItem.uid).set({ twitchname: newName.toLowerCase().trim() }, { merge: true }).then(async result => {
        console.log("success")
      }).catch(err => {
        console.log(err)
      })
    },
    activateNameChange(item, bool) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(item.uid).set({ License: { "changeTwitchnameAllowed": bool} }, { merge: true }).then(async result => {
        await new Promise(r => setTimeout(r, (20 * 1000)));
      }).catch(err => {
        console.log(err)
      })
    },
    resolveUserData() {
      let userData = getUserData()
      this.isjemkein= userData.id === 'towa5MUabmfPHflpRoxMVCrmDaj2';
    },
    refreshForCurrentViews() {
      console.log("refresh")
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).get().then(async result => {
        await result.forEach(x => {
          console.log('INFOREACH')
          console.log(x.data().twitchname)
          this.sendPostRequest(x.data().twitchname).then(newViewers => {
            console.log(newViewers.data.user.stream)
            if (newViewers.data.user.stream === null || newViewers.data.user.stream === undefined) {
              sx.collection('User')
                  .doc(x.id).set({
                Botpanel: {
                  Active: false,
                },
              }, {merge: true}).then(() => {
                console.log('SUCCESS UPDATED')
              })
                  .catch(err => {
                    console.log(err)
                  })
            } else {
              sx.collection('User')
                  .doc(x.id).set({
                Botpanel: {
                  currentViews: newViewers.data.user.stream.viewersCount,
                },
              }, {merge: true}).then(() => {
                console.log('SUCCESS UPDATED')
              })
                  .catch(err => {
                    console.log(err)
                  })
            }

          })
        })
        this.getUpdates()
      })
    },
    getUpdates() {
      console.log("test")
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Package', '!=', 'None').get().then(result => {
        console.log("GO")
        this.items = []
        let id = 0
        this.totalActiveViews = 0
        result.forEach(x => {
          const doc = x.data()
          // doc.Botpanel?.
          let userStatus = 0
          if(doc.Botpanel.Active) {
            userStatus = 2
            this.totalActiveViews = parseInt(this.totalActiveViews) + parseInt(doc.Botpanel?.Views)
          }
          else userStatus = 1

          const date1 = doc.License.licensebeginning.toDate()
          const date2 = new Date()
          const differenceInTime = date2.getTime() - date1.getTime()

          // To calculate the no. of days between two dates
          const days = differenceInTime / (1000 * 3600 * 24)
          let remainingduration = doc.License.TotalDays - days.toFixed(0)

          const newObject = {
            id: id,
            // eslint-disable-next-line global-require
            geminosname: doc.username,
            twitchname: doc.twitchname,
            license: doc.License?.licensekey,
            duration: remainingduration,
            durationLeft: remainingduration,
            durationTotal: doc.License.TotalDays,
            plan: doc?.Package,
            viewers: this.resolveViews(doc),
            status: userStatus,
            uid: x.id
          }
          this.items.push(newObject)
          id += 1
        })
        console.log(this.items)

      })
    },
    InstantRestartAllActive() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).get().then(result => {
        sx.collection('System')
            .doc('NGROK')
            .get()
            .then(snap2 => {
              let count = 0
              result.forEach(x => {
                count += 1
                this.instantRestartUser({"uid": x.id})
              })
            })
      })
    },
    restartAutoStartAll() {
      const sx = this.$firebase.firestore()
      let docs = sx.collection("User").where("Botpanel.AutoStart", "==", true)
      let count = 0
      const refillAccount = this.$firebase.functions().httpsCallable('startStopAutoStartForAccount')

      docs.get().then(async results => {
        console.log(results.docs.length)
            for (const result of results.docs) {
              count = count+1
              const userData = result.data()
              await new Promise(r => setTimeout(r, (0.005 * count * 1000)));

              refillAccount({twitchname: userData.twitchname, firebaseID: result.id}).then(result => {
                console.log(result)
              })
            }
      })
      // const resetAllListeners = this.$firebase.functions().httpsCallable('resetAllListeners')
      // resetAllListeners({}).then(result => {
      //   console.log(result)
      // })
    },
    restartAutoStartForAccount() {
      const twitchname = "mewliesdl"
      const firebaseID = "towa5MUabmfPHflpRoxMVCrmDaj2"
      const refillAccount = this.$firebase.functions().httpsCallable('startStopAutoStartForAccount')
      refillAccount({twitchname, firebaseID}).then(result => {
        console.log(result)
      })
    },
    startActive() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).get().then(result => {
        sx.collection('System')
          .doc('NGROK')
          .get()
          .then(snap2 => {
            let count = 0
            result.forEach(x => {
              count += 1
              setTimeout(() => {
                fetch(`https://${snap2.data().Link}.ngrok.io/start/${x.id}`)
                  .then(response => {
                    sx.collection('User').doc(x.id).set({"License": { "LastStart": new Date() } }, { merge: true })
                  })
              }, 3000 * count)
              console.log(`https://${snap2.data().Link}.ngrok.io/start/${x.id}/${x.data().Botpanel.Chatbot}/${x.data().twitchname}/${x.data().Botpanel.Views}/${x.data().Botpanel.chatinterval[0]}/${x.data().Botpanel.chatinterval[1]}`)
            })
          })
      })
    },
    resolveProgressBarVariant(item) {
      if (item.duration < item.durationTotal / 2 && item.duration > item.durationTotal / 4) return 'primary'
      if (item.duration < item.durationTotal / 4 && item.duration > 1) return 'warning'
      if (item.duration < 1) return 'danger'
      return 'info'
    },
    async restartUser(item) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": false} }, { merge: true }).then(async result => {
        await new Promise(r => setTimeout(r, (20 * 1000)));
            sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": true}, "License": { "LastStart": new Date() } }, { merge: true })
      }).catch(err => {
        console.log(err)
      })
    },
    async refetchUser(uid) {
      let test = this.items
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(uid).get().then(result => {
        let doc = result.data()
        const objIndex = this.items.findIndex(obj => obj.uid === uid);
        let userStatus = 0
        if(doc.Botpanel.Active) {
          userStatus = 2
          this.totalActiveViews = parseInt(this.totalActiveViews) + parseInt(doc.Botpanel?.Views)
        }
        else userStatus = 1

        const date1 = doc.License.licensebeginning.toDate()
        const date2 = new Date()
        const differenceInTime = date2.getTime() - date1.getTime()

        // To calculate the no. of days between two dates
        const days = differenceInTime / (1000 * 3600 * 24)
        let remainingduration = doc.License.TotalDays - days.toFixed(0)
        console.log(doc.License.TotalDays)
        const newObject = {
          id: this.items[objIndex].id,
          // eslint-disable-next-line global-require
          geminosname: doc.username,
          twitchname: doc.twitchname,
          license: doc.License?.licensekey,
          duration: remainingduration,
          durationLeft: remainingduration,
          durationTotal: doc.License.TotalDays,
          plan: doc?.Package,
          viewers: this.resolveViews(doc),
          status: userStatus,
          uid: uid
        }
        console.log(`updated User ${uid} with index ${objIndex}`)
        this.$set(this.items, objIndex, newObject)
        console.log(this.items[objIndex])
      })
    },
    async instantRestartUser(item) {
      this.waitingActive = true
      const sx = this.$firebase.firestore()
      const userDoc = await sx.collection('User').doc(item.uid).get()
      sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": false, DripFeedTime: 25} }, { merge: true }).then(async result => {
        for(let i = 0; i< 20; i++) {
          await new Promise(r => setTimeout(r, (1000)));
          this.barProgress += 100/40
        }
        const snap2 = await sx.collection('System').doc('NGROK').get()
        await fetch(`https://${snap2.data().Link}.ngrok.io/start/${item.uid}`)
        for(let i = 0; i< 20; i++) {
          await new Promise(r => setTimeout(r, (1000)));
          this.barProgress += 100/40
        }
        sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": true, DripFeedTime: userDoc.data().Botpanel.DripFeedTime}, "License": { "LastStart": new Date() } }, { merge: true })
        this.waitingActive = false
        this.barProgress = 0
      }).catch(err => {
        console.log(err)
      })
    },
    extendPackage(item) {
      const sx = this.$firebase.firestore()
      const obj = {
        TotalDays: item.durationTotal + 1,
      }
      // sx.collection('userTest').doc(item.id).update({ test: obj })
      sx.collection('User').doc(item.uid).set({ License: obj }, { merge: true }).then(() => {
        this.refetchUser(item.uid)
      })
    },
    stopPackage(item) {
      console.log("ENDINGPACKAGE")
      console.log(item)
      const sx = this.$firebase.firestore()
      let obj = {}
      if (item.durationTotal === undefined) {
        obj = {
          licenseKeyOld: item.license, licensekey: 'none', TotalDays: 0, TotalDaysOld: 0,
        }
      } else {
        obj = {
          licenseKeyOld: item.license, licensekey: 'none', TotalDays: 0, TotalDaysOld: item.durationTotal,
        }
      }
      const bpObj = {
        Active: false,
      }
      console.log(obj)
      console.log(item.uid)
      sx.collection('User').doc(item.uid).set({ License: obj, Package: 'None', Botpanel: bpObj }, { merge: true }).then(result => {
        console.log(result)
        this.getUpdates()
      }).catch(err => {
        console.log(err)
      })
    },
    resolveViews(item) {
      let amountString = ""
      let statusOfBadge = "light-success"
      if (item.Botpanel?.currentViews === null || item.Botpanel?.currentViews === undefined) {
        amountString= `0 / ${item.Botpanel?.Views}`
      } else amountString= `${item.Botpanel.currentViews}/${item.Botpanel?.Views}`
      let calculatedDate = new Date(item.License?.LastStart.toDate().getTime() + item.Botpanel.DripFeedTime*1000)
      if(item.Botpanel.currentViews-item.Botpanel?.Views < 0) statusOfBadge = "light-danger"
      if(calculatedDate > new Date()) statusOfBadge = "light-warning"
      if(!item.Botpanel.Active) statusOfBadge = "light-secondary"
      let views = {
        amountString,
        statusOfBadge
      }
      return views

    },

    async sendPostRequest(username) {
      const userAgent = {
        Windows: {
          CHROME: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.104 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:84.0) Gecko/20100101 Firefox/84.0',
        },
        Linux: {
          CHROME: 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.96 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
        },
      }
      const clientId = 'kimne78kx3ncx6brgo4mv6wki5h1ko'
      const jsonOperation = {
        operationName: 'VideoPlayerStreamInfoOverlayChannel',
        variables: {
          channel: username,
        },
        extensions: {
          persistedQuery: {
            version: 1,
            sha256Hash: 'a5f2e34d626a9f4f5c0204f910bab2194948a9502089be558bb6e779a9e1b3d2',
          },
        },
      }

      const response = await fetch(
        'https://gql.twitch.tv/gql', {
          method: 'POST',
          headers: {
            Authorization: 'Oauth c7oso8b4qr09bd3gno0yqykvgv86wy',
            'Client-Id': clientId,
            'User-Agent': userAgent,
          },
          body: JSON.stringify(jsonOperation),
        },
      )
      return response.json()
    },
    resolveStatus(item) {
      console.log(item.Botpanel?.Active)
      if (item.Botpanel?.Active) {
        return 'Started'
      }
      return 'Stopped'
    },
    getTwitchLink(user) {
      return `https://www.twtich.tv/${user.twitchname}`
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
